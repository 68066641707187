const { numberFormat } = require("highcharts");
const { css } = require("jquery");

document.addEventListener("turbolinks:load", function() {
    let width = window.innerWidth;

    $('.circle-center-count').each(function(){
        if(width >= 1200){
            countFontSize($(this), 16, 21, 28);
        }else if(width <= 414){
            countFontSize($(this), '5.5vw', '7vw', '7.9vw');
        }else if(width <= 768){
            countFontSize($(this), '2.8vw', '3.2vw', '4.1vw');
        }else{
            countFontSize($(this), '1vw', '1.3vw', '1.7vw');
        }
    });

    function countFontSize(element, l, m, n){
        if(element.text().length > 14){
            element.css({'fontSize': l})
        }else if(element.text().length > 10){
            element.css({'fontSize': m})
        }else if(element.text().length > 7){
            element.css({'fontSize': n})
        }
    }

    function eachFontSize(element, l, m, n){
        element.each(function(){
            countFontSize($(this), l, m, n)
        });
    }

    $(window).on("orientationchange", function(){
        if(width > 1024){
            if(window.orientation === 0){
                if(window.innerWidth > 768){
                    eachFontSize($('.circle-center-count'), '1vw', '1.3vw', '1.7vw');
                }
            }else if(window.orientation === 90){
                if(window.innerWidth > 1024){
                    eachFontSize($('.circle-center-count'), 16, 21, 28);
                }else if(window.innerWidth > 768){
                    eachFontSize($('.circle-center-count'), '1vw', '1.3vw', '1.7vw');
                }
            }
        }else if(width <= 414){
            if(window.orientation === 0){
                if(window.innerWidth > 414){
                    eachFontSize($('.circle-center-count'), '5.5vw', '7vw', '7.9vw');
                }
            }else if(window.orientation === 90){
                if(window.innerHeight > 768){
                    eachFontSize($('.circle-center-count'), '1vw', '1.3vw', '1.7vw');
                    $('.all-circle-graph').css({'marginLeft': 0});
                    $('.next').hide();
                    $('.prev').hide();
                }else if(window.innerWidth <= 414 && window.innerHeight <= 768){
                    eachFontSize($('.circle-center-count'), '2.8vw', '3.2vw', '4.1vw');
                }
            }
        }else if(width <= 768){
            if(window.orientation === 0){
                if(window.innerWidth <= 768){
                    eachFontSize($('.circle-center-count'), '5.5vw', '7vw', '7.9vw');
                }else if(window.innerWidth > 768){
                    eachFontSize($('.circle-center-count'), '2.8vw', '3.2vw', '4.1vw');
                }
            }else if(window.orientation === 90){
                if(window.innerWidth <= 414){
                    eachFontSize($('.circle-center-count'), '2.8vw', '3.2vw', '4.1vw');
                }else if(window.innerWidth <= 768){
                    eachFontSize($('.circle-center-count'), '1vw', '1.3vw', '1.7vw');
                }
            }
        }else if(width <= 1024){
            if(window.orientation === 0){
                if(window.innerHeight <= 414){
                    eachFontSize($('.circle-center-count'), '5.5vw', '7vw', '7.9vw');
                }else if(window.innerWidth >1024){
                    eachFontSize($('.circle-center-count'), '1vw', '1.3vw', '1.7vw');
                }else if(window.innerWidth > 768){
                    eachFontSize($('.circle-center-count'), '2.8vw', '3.2vw', '4.1vw');
                }
            }else if(window.orientation === 90){
                if(window.innerWidth > 1024){
                    eachFontSize($('.circle-center-count'), 16, 21, 28);
                }else{
                    eachFontSize($('.circle-center-count'), '1vw', '1.3vw', '1.7vw');
                }
            }
        }
    })

    const bgOrigin = '#FAFBFD'
    const bgClicked = '#fff'
    // ユーザー数
    function user(color){
            Highcharts.chart({
            colors: ["#43A6DA", "#91D1F2", "#BEE6FC"],
            chart: {
                backgroundColor: color,
                renderTo: 'userCircle',
                // どんなタイプのグラフを表示させるか
                type: 'pie'
            },
            title: {
                // グラフのタイトル
                text: 'ユーザー数',
                style: {
                    marginTop: 10
                }
            },
            tooltip: {
                enabled: false // カーソルを置いたときに吹き出しを表示しない
            },
            exporting: { enabled: false },
            plotOptions: {
                pie: {
                    // 円グラフの内側のサイズ
                    innerSize: '79%',
                    style: {
                        marginTop: '-100px'
                    }
                }
            },
            // 表示させるデータ
            series: [
                {
                    name: 'ユーザー数',
                    dataLabels:
                        {
                            enabled:false
                        },
                    data: [
                        // 進捗25%、残り75%
                        [ 'モバイル', gon.userMobile],
                        [ 'PC', gon.userPc ],
                        [ 'タブレット', gon.userTablet ]
                    ]
                }
            ],
            credits:
                {
                    enabled: false,
                }
        });
    }

    user(bgOrigin)
    
    //ページビュー
    function pageView(color){
            Highcharts.chart({
            colors: ["#1CAFB7", "#81DCE2", "#CFF9FC"],
            chart: {
                backgroundColor: color,
                renderTo: 'pageCircle',
                // どんなタイプのグラフを表示させるか
                type: 'pie'
            },
            title: {
                // グラフのタイトル
                text: 'ページビュー'
            },
            tooltip: {
                enabled: false // カーソルを置いたときに吹き出しを表示しない
            },
            exporting: { enabled: false },
            plotOptions: {
                pie: {
                    // 円グラフの内側のサイズ
                    innerSize: '79%'
                }
            },
            // 表示させるデータ
            series: [
                {
                    name: 'ページビュー',
                    dataLabels:
                        {
                            enabled:false
                        },
                    data: [
                        // 進捗25%、残り75%
                        [ 'モバイル', gon.pageViewMobile ],
                        [ 'PC', gon.pageViewPc ],
                        [ 'タブレット', gon.pageViewTablet ]
                    ]
                }
            ],
            credits:
                {
                    enabled: false,
                }
        });
    }

    pageView(bgOrigin)

    //応募数
    function app(color){
            Highcharts.chart({
            colors: ["#E66118", "#FFA26E", "#FFD7C1"],
            chart: {
                backgroundColor: color,
                renderTo: 'applicationCircle',
                // どんなタイプのグラフを表示させるか
                type: 'pie'
            },
            title: {
                // グラフのタイトル
                text: '応募数'
            },
            tooltip: {
                enabled: false // カーソルを置いたときに吹き出しを表示しない
            },
            exporting: { enabled: false },
            plotOptions: {
                pie: {
                    // 円グラフの内側のサイズ
                    innerSize: '79%'
                }
            },
            // 表示させるデータ
            series: [
                {
                    name: '応募数',
                    dataLabels:
                        {
                            enabled:false
                        },
                    data: [
                        // 進捗25%、残り75%
                        [ 'モバイル', gon.applicationMobile ],
                        [ 'PC', gon.applicationPc ],
                        [ 'タブレット', gon.applicationTablet ]
                    ]
                }
            ],
            credits:
                {
                    enabled: false,
                }
        });
    }

    app(bgOrigin)

    //平均滞在時間
    function avgStay(color){
            Highcharts.chart({
            colors: ["#64BE59", "#9BD195", "#CFEBCC"],
            chart: {
                backgroundColor: color,
                renderTo: 'stayTimeCircle',
                // どんなタイプのグラフを表示させるか
                type: 'pie'
            },
            title: {
                // グラフのタイトル
                text: '平均滞在時間'
            },
            tooltip: {
                enabled: false // カーソルを置いたときに吹き出しを表示しない
            },
            exporting: { enabled: false },
            plotOptions: {
                pie: {
                    // 円グラフの内側のサイズ
                    innerSize: '79%'
                }
            },
            // 表示させるデータ
            series: [
                {
                    name: '平均滞在時間',
                    dataLabels:
                        {
                            enabled:false
                        },
                    data: [
                        // 進捗25%、残り75%
                        [ 'モバイル', gon.averageTimePc ],
                        [ 'PC', gon.averageTimeMobile ],
                        [ 'タブレット', gon.averageTimeTablet ]
                    ]
                }
            ],
            credits:
                {
                    enabled: false,
                }
        });
    }

    avgStay(bgOrigin)

    //平均ページビュー
    function avgpage(color) {
            Highcharts.chart({
            colors: ["#F25B8D", "#FF98BA", "#FFD8E5"],
            chart: {
                backgroundColor: color,
                renderTo: 'pageAverageCircle',
                // どんなタイプのグラフを表示させるか
                type: 'pie'
            },
            title: {
                // グラフのタイトル
                text: '平均ページビュー'
            },
            tooltip: {
                enabled: false // カーソルを置いたときに吹き出しを表示しない
            },
            exporting: { enabled: false },
            plotOptions: {
                pie: {
                    // 円グラフの内側のサイズ
                    innerSize: '79%',
                    tooltip: {
                        shared: true
                    }
                }
            },
            // 表示させるデータ
            series: [
                {
                    name: '平均ページビュー',
                    dataLabels:
                        {
                            enabled:false
                        },
                    data: [
                        // 進捗25%、残り75%
                        [ 'モバイル', gon.averagePageViewPc ],
                        [ 'PC', gon.averagePageViewMobile ],
                        [ 'タブレット', gon.averagePageViewTablet ]
                    ]
                }
            ],
            credits:
                {
                    enabled: false,
                }
        });
    }

    avgpage(bgOrigin)

    //折れ線グラフ
    let date_interval = 1;
    if (width <= 414){
        if(gon.line_device_date.length >= 360){
            date_interval = 73;
        }else if(gon.line_device_date.length >= 330){
            date_interval = 67;
        }else if(gon.line_device_date.length >= 300){
            date_interval = 61;
        }else if(gon.line_device_date.length >= 270){
            date_interval = 55;
        }else if(gon.line_device_date.length >= 240){
            date_interval = 49;
        }else if(gon.line_device_date.length >= 210){
            date_interval = 43;
        }else if(gon.line_device_date.length >= 180){
            date_interval = 37;
        }else if(gon.line_device_date.length >= 150){
            date_interval = 31;
        }else if(gon.line_device_date.length >= 120){
            date_interval = 25;
        }else if(gon.line_device_date.length >= 90){
            date_interval = 19;
        }else if(gon.line_device_date.length >= 60){
            date_interval = 13;
        }else if(gon.line_device_date.length >= 30){
            date_interval = 7;
        }else if(gon.line_device_date.length >= 22){
            date_interval = 4;
        }else if(gon.line_device_date.length >= 14){
            date_interval = 3;
        }else if(gon.line_device_date.length >= 8){
            date_interval = 2;
        }
    }else if(width <= 768){
        dataInterval();
    }else if(width <= 1024){
        dataInterval();
    }else{
        dataInterval();
    }

    function dataInterval(){
        if(gon.line_device_date.length >= 360){
            date_interval = 52;
        }else if(gon.line_device_date.length >= 330){
            date_interval = 48;
        }else if(gon.line_device_date.length >= 300){
            date_interval = 43;
        }else if(gon.line_device_date.length >= 270){
            date_interval = 39;
        }else if(gon.line_device_date.length >= 240){
            date_interval = 35;
        }else if(gon.line_device_date.length >= 210){
            date_interval = 30;
        }else if(gon.line_device_date.length >= 180){
            date_interval = 26;
        }else if(gon.line_device_date.length >= 150){
            date_interval = 22;
        }else if(gon.line_device_date.length >= 120){
            date_interval = 18;
        }else if(gon.line_device_date.length >= 90){
            date_interval = 13;
        }else if(gon.line_device_date.length >= 60){
            date_interval = 9;
        }else if(gon.line_device_date.length >= 30){
            date_interval = 6;
        }else if(gon.line_device_date.length >= 22){
            date_interval = 4;
        }else if(gon.line_device_date.length >= 14){
            date_interval = 3;
        }else if(gon.line_device_date.length >= 8){
            date_interval = 2;
        }
    }

    function device_line_chart(color_mobile, color_pc, color_tablet){
        Highcharts.chart({
            chart: {
                renderTo: 'transition', //表示するid名
                defaultSeriesType: 'area', //グラフのタイプline, spline, area, areaspline, column, bar, pie,scatter
            },
            title: {
                text: '' //グラフタイトル
            },
            xAxis: { //X軸
                labels: {
                    rotation: 0
                },
                categories: gon.line_device_date,
                tickInterval: date_interval,
                reserveSpace: true,
            },
            yAxis: { //Y軸
                title: {text: ''} //タイトル
            },
            plotOptions: {
                area: {
                    stacking: 'normal'
                }
            },
            exporting: {
                enabled: false
            },
            legend: { //項目名
                align: 'left',
                verticalAlign: 'top',
                symbolRadius: 0,
                itemDistance: 10,
                itemStyle: {
                    fontWeight: 'normal'
                }
                
            },
            series: [ //ここからグラフ内容指定
                { 
                    name: 'モバイル',
                    data: gon.line_mobile,
                    color: color_mobile
                },{
                    name: 'PC',
                    data: gon.line_pc,
                    color: color_pc
                },{
                    name: 'タブレット',
                    data: gon.line_tablet,
                    color: color_tablet
                }],
            credits: {
                enabled: false,
            }
        });
    }

    //新規orリピーター円グラフ
    function judge_circle(color_new, color_repeat){
        Highcharts.chart({
            colors: [color_new, color_repeat],
            chart: {
                backgroundColor:'#FFF',
                renderTo: 'judgeCircle',
                // どんなタイプのグラフを表示させるか
                type: 'pie'
            },
            title: {
                // グラフのタイトル
                text: ''
            },
            exporting: { enabled: false },
            plotOptions: {
                pie: {
                    // 円グラフの内側のサイズ
                    innerSize: '79%',
                    dataLabels: {
                        formatter: function() {
                             return '<b>' + this.point.name+ '</b>' +'<br>'+ Math.round(this.percentage * 10) / 10 +'%';
                             },
                        enabled: true,
                        softConnector: false,
                        distance: 5,
                    },
                    
                }
            },
            // 表示させるデータ
            series: [
                {
                    name: gon.data_name + '数',
                    data: [
                        // 進捗25%、残り75%
                        [ gon.newVisitorTitle, gon.newVisitor ],
                        [ gon.returningVisitorTitle, gon.returningVisitor ]
                    ]
                }
            ],
            credits:
                {
                    enabled: false,
                }
        });
    }
    //比較グラフ

    let histgram_interval = 1;
    categoryInterval();

    function categoryInterval(){
        if(gon.allValuesCategory.length >= 360){
            histgram_interval = 51;
        }else if(gon.allValuesCategory.length >= 330){
            histgram_interval = 47;
        }else if(gon.allValuesCategory.length >= 300){
            histgram_interval = 42;
        }else if(gon.allValuesCategory.length >= 270){
            histgram_interval = 38;
        }else if(gon.allValuesCategory.length >= 240){
            histgram_interval = 34;
        }else if(gon.allValuesCategory.length >= 210){
            histgram_interval = 29;
        }else if(gon.allValuesCategory.length >= 180){
            histgram_interval = 25;
        }else if(gon.allValuesCategory.length >= 150){
            histgram_interval = 21;
        }else if(gon.allValuesCategory.length >= 120){
            histgram_interval = 17;
        }else if(gon.allValuesCategory.length >= 90){
            histgram_interval = 12;
        }else if(gon.allValuesCategory.length >= 60){
            histgram_interval = 8;
        }else if(gon.allValuesCategory.length >= 30){
            histgram_interval = 5;
        }else if(gon.allValuesCategory.length >= 22){
            histgram_interval = 4;
        }else if(gon.allValuesCategory.length >= 14){
            histgram_interval = 3;
        }else if(gon.allValuesCategory.length >= 8){
            histgram_interval = 2;
        }
    }

    let maxValue = Math.max.apply(null, gon.allValues);
    let l = 0;
    let array_rough_data = [];
    while(l < gon.allValues.length){
        array_rough_data.push(gon.allValues[l]);
        l++
    }
    
    let m = 1;
    while(m*10 < maxValue + 10){
        let n = 0;
        while(n < array_rough_data.length){
            if(String(array_rough_data[n]).slice(-1) != '0'){
                if(array_rough_data[n] < m*10){
                    array_rough_data[n] = m*10;
                }
            }
            n++;
        }
        m++;
    }

    //比較グラフの色付けの処理
    let histogram_array = [];
    let rough_histogram_array = [];
    let user_index;
    let user_value;
    let rough_value;
    function my_position(color, rough_color){
        let category_num = 0;
        while(category_num < gon.allValuesCategory.length){
            if(gon.selfUserValue == gon.allValuesCategory[category_num]){
                user_index = category_num;
                break;
            }
            category_num++;
        }
        let a = 0;
        while(a < gon.allValues.length){
            histogram_array.push({y: gon.allValues[a], color: '#fff'});
            rough_histogram_array.push({y: array_rough_data[a], color: '#C0C0C0'});
            a++;
        }
        if(gon.selfUserValue != null) {
            histogram_array[user_index].color = color;
            rough_histogram_array[user_index].color = rough_color;
            user_value = histogram_array[user_index].y;
            rough_value = rough_histogram_array[user_index].y;
        }
    }
    
    function histogram(){
        var chart = Highcharts.chart({
            chart: {
                renderTo: 'histogram', //表示するid名
                defaultSeriesType: 'column', //グラフのタイプline, spline, area, areaspline, column, bar, pie,scatter
            },
            title: {
                // グラフのタイトル
                text: ''
            },
            xAxis: { //X軸
                title: { text: '(' + gon.data_name + ')' },
                lineWidth: 0.5,
                categories: gon.allValuesCategory, //ラベル
                labels: {
                    rotation: 0
                },
                tickInterval: histgram_interval
            },
            yAxis: [{ //Y軸
                title: {
                    text: '(原稿数)',
                    align: "high",
                    textAlign: "left",
                    rotation: 0,
                    x: -12
                },
                gridLineColor: '#fff',
                lineWidth: 0.5,
                labels: {
                    enabled: false
                }
            },{
                title: {
                    text: ''
                },
                gridLineColor: '#fff',
                lineWidth: 0,
                labels: {
                    enabled: false
                },
                plotLines: [{
                    value: rough_value,
                    zIndex: 5,
                    width: 1,
                    color: '#81DCE2',
                    dashStyle: 'shortdash',
                    label: {
                        text: user_value,
                        style: {
                            color: '#707070'
                        },
                        align: 'left',
                        x: -30,
                        y: 5
                    }
                }]
            }],

            tooltip: {
                shared: true,
                formatter: function(){
                    return gon.data_name + '：' + this.x + '<br>原稿数　：' + this.y
                }
            },

            plotOptions: {
                area: {
                    stacking: 'normal'
                }
            },
            exporting: {
                enabled: false
            },
            legend: { //項目名
                enabled: false
            },
            series: [ //ここからグラフ内容指定
                {
                    name: '原稿数',
                    type: 'line',
                    data: gon.allValues,
                    yAxis: 0,
                    color: '#fff'
                },{
                    name: 'サブ',
                    type: 'column',
                    data: rough_histogram_array,
                    yAxis: 1
                }],
            credits: {
                enabled: false
            }
        });

        let num = 1;
        while(num < maxValue/10){
            addPlotLines(num*10);
            num++;
        }
        function addPlotLines(value){
            chart.yAxis[0].addPlotLine({
                value: value,
                color: '#fff',
                zIndex: 3
            })
        }
    }

    //曜日毎の棒グラフ
    function day_stick_chart(color){
        new Highcharts.Chart({
            chart: {
                renderTo: 'day_of_week_stick', //表示するid名
                defaultSeriesType: 'column', //グラフのタイプline, spline, area, areaspline, column, bar, pie,scatter
            },
            title: {
                text: '曜日毎の平均' + gon.column_title, //グラフタイトル
                margin: 40,
                align: 'left'
            },
            xAxis: { //X軸
                categories: gon.column_week_day, //ラベル
                labels: {
                    rotation: 0
                }
            },
            yAxis: { //Y軸
                title: {text: ''} //タイトル
            },
            plotOptions: {
                area: {
                    stacking: 'normal'
                }
            },
            exporting: {
                enabled: false
            },
            legend: { //項目名
                enabled: false
            },
            series: [ //ここからグラフ内容指定
                { 
                    name: gon.data_name,
                    data: gon.column_week_data,
                    color: color
                }],
            credits: {
                enabled: false
            }
        });
    }

    // <ーーーーーーーーーーーーーーーーーーーーー数値順に色を変えるコードーーーーーーーーーーーーーーーーーーーーーー>
    // var day = new Highcharts.Chart({
    //     chart: {
    //         renderTo: 'day_of_week_stick', //表示するid名
    //         defaultSeriesType: 'column', //グラフのタイプline, spline, area, areaspline, column, bar, pie,scatter
    //     },
    //     title: {
    //         text: '曜日毎の平均' + gon.column_title, //グラフタイトル
    //         margin: 40,
    //         align: 'left'
    //     },
    //     xAxis: { //X軸
    //         categories: gon.column_week_day //ラベル
    //     },
    //     yAxis: { //Y軸
    //         title: {text: ''} //タイトル
    //     },
    //     plotOptions: {
    //         area: {
    //             stacking: 'normal'
    //         }
    //     },
    //     exporting: {
    //         enabled: false
    //     },
    //     legend: { //項目名
    //         enabled: false
    //     },
    //     series: [ //ここからグラフ内容指定
    //         { 
    //             name: gon.data_name,
    //             data: gon.column_week_data
    //         }],
    //     credits: {
    //         enabled: false
    //     }
    // });

    // // 取得したデータの重複を消す
    // var data_filter = gon.column_week_data.filter(function (x, i, self) {
    //     return self.indexOf(x) === i;
    // });
    // // データが大きい順に並べ替える
    // var data_sort = data_filter.sort( (a,b) =>  b-a   );
    // var column_data_sort = [];
    // // データの大きさと色を指定してデータの配列を作る
    // var a = 0;
    // while(a < data_sort.length){
    //     column_data_sort.push({y: data_sort[a], color: ''});
    //     a++;
    // }
    // // 作ったデータの配列の色をデータが大きい順に指定していく
    // var b = 0;
    // while(b < data_sort.length){
    //     if(b == 0){
    //         column_data_sort[b].color = '#FD8039';
    //     }else if(b == 1){
    //         column_data_sort[b].color = '#1B4C67';
    //     }else if(b == 2){
    //         column_data_sort[b].color = '#217A88';
    //     }else if(b == 3){
    //         column_data_sort[b].color = '#3796A4';
    //     }else if(b == 4){
    //         column_data_sort[b].color = '#29B8C0';
    //     }else if(b == 5){
    //         column_data_sort[b].color = '#81DCE2';
    //     }else if(b == 6){
    //         column_data_sort[b].color = '#D4F2F4';
    //     }
    //     b++
    // }

    // // 表示するデータが高い値から先ほど作ったデータの配列の色を渡す
    // var length = day.series[0].data.length;
    // var num = 0
    // console.log(num)
    // while(num < data_sort.length){
    //     var column_color = column_data_sort[num].color;
    //     var count = 0;
    //     while(count < length){
    //         if(column_data_sort[num].y == day.series[0].data[count].options.y){
    //             day.series[0].data[count].color = column_color;
    //             day.series[0].data[num].update();
    //         }
    //         count++;
    //     }
    //     num++
    // }
    // <ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー>
   

    //時間毎の折れ線グラフ
    function time_line_chart(color){
        new Highcharts.Chart({
            chart: {
                renderTo: 'day_of_hour_stick', //表示するid名
                defaultSeriesType: 'line', //グラフのタイプline, spline, area, areaspline, column, bar, pie,scatter
            },
            title: {
                text: '時間毎' + gon.line_title, //グラフタイトル
                margin: 40,
                align: 'left'
            },
            xAxis: { //X軸
                categories: gon.line_time_hour //ラベル
            },
            yAxis: { //Y軸
                title: {text: ''} //タイトル
            },
            plotOptions: {
                area: {
                    stacking: 'normal'
                }
            },
            exporting: {
                enabled: false
            },
            legend: { //項目名
                enabled: false
            },
            series: [ //ここからグラフ内容指定
                { 
                    name: gon.data_name,
                    data: gon.line_time_data,
                    color: color
                }],
            credits: {
                enabled: false,
            }
        });
    }

    const circle_mobile = $('.device-type').children('li').eq(2).children('span');
    const circle_pc = $('.device-type').children('li').eq(1).children('span');
    const circle_tablet = $('.device-type').children('li').eq(0).children('span');
    const mobile = $('.line-device-type').children('li').eq(2).children('span');
    const pc = $('.line-device-type').children('li').eq(1).children('span');
    const tablet = $('.line-device-type').children('li').eq(0).children('span');
    const device_rate = $('.device').children('span');
    const lank_sentence = $('.lank-sentence').children('p');
    const sales_table_head = $('.salse_table-information-title').children('th');
    const sales_table_head_sum = sales_table_head.eq(4);

    function graph_color(color_mobile, color_pc, color_tablet, color_table_sum){
        device_line_chart(color_mobile, color_pc, color_tablet);
        judge_circle(color_mobile, color_pc);
        day_stick_chart(color_mobile);
        time_line_chart(color_mobile);
        my_position('#fff', color_pc)
        histogram();
        circle_mobile.css({'color': color_tablet});
        circle_pc.css({'color': color_pc});
        circle_tablet.css({'color': color_mobile});
        mobile.css({'color': color_mobile});
        pc.css({'color': color_pc});
        tablet.css({'color': color_tablet});
        device_rate.css({'color': color_mobile});
        lank_sentence.css({'color': color_mobile});
        sales_table_head.css({'backgroundColor': color_mobile});
        sales_table_head_sum.css({'backgroundColor': color_table_sum});
        $('#data_title').children('div').css({'borderColor': color_mobile});
        $('.area-date').css({'backgroundColor':'#E5E5ED'});
        $('.all-area').css({'backgroundColor':'#E5E5ED'});
    }

    function pseudo_class(){
        $('head').append('<style>#pageAverageCircle:before { display: none} </style>');
        $('head').append('<style>#pageAverageCircle:after { display: none} </style>');
        $('head').append('<style>#applicationCircle:before { display: none} </style>');
        $('head').append('<style>#applicationCircle:after { display: none} </style>');
        $('head').append('<style>#pageCircle:before { display: none} </style>');
        $('head').append('<style>#pageCircle:after { display: none} </style>');
        $('head').append('<style>#stayTimeCircle:before { display: none} </style>');
        $('head').append('<style>#stayTimeCircle:after { display: none} </style>');
        $('head').append('<style>#userCircle:before { display: none} </style>');
        $('head').append('<style>#userCircle:after { display: none} </style>');
    }

    $('#pageCircle').css({'border': 'none'});
    $('#applicationCircle').css({'border': 'none'});
    
    // グラフの色を変える
    if(location.pathname.match(/average_page_view/)){
        const color_mobile = "#F25B8D";
        const color_pc = "#FF98BA";
        const color_tablet = "#FFD8E5";
        const color_table_sum = "#D64676";
        pseudo_class();
        $('#pageAverageCircle').css({'border': '1px solid #43A6DA', 'boxShadow': '0px 3px 6px #00000029'});
        $('head').append('<style>#pageAverageCircle:before { display: block} </style>');
        $('head').append('<style>#pageAverageCircle:after { display: block} </style>');
        avgpage(bgClicked);
        $('#pageAverageCircle').css({'backgroundColor': bgClicked});
        graph_color(color_mobile, color_pc, color_tablet, color_table_sum);
        carousel(-2);
    }else if(location.pathname.match(/apply/)){
        const color_mobile = "#E66118";
        const color_pc = "#FFA26E";
        const color_tablet = "#FFD7C1";
        const color_table_sum = "#E66118";
        pseudo_class();
        $('#applicationCircle').css({'border': '1px solid #43A6DA', 'boxShadow': '0px 3px 6px #00000029'});
        $('head').append('<style>#applicationCircle:before { display: block} </style>');
        $('head').append('<style>#applicationCircle:after { display: block} </style>');
        app(bgClicked);
        $('#applicationCircle').css({'backgroundColor': bgClicked});
        graph_color(color_mobile, color_pc, color_tablet, color_table_sum);
        carousel(0);
    }else if(location.pathname.match(/page_view/)){
        const color_mobile = "#1CAFB7";
        const color_pc = "#81DCE2";
        const color_tablet = "#CFF9FC";
        const color_table_sum = "#3796A4";
        pseudo_class();
        $('#pageCircle').css({'border': '1px solid #43A6DA', 'boxShadow': '0px 3px 6px #00000029'});
        $('head').append('<style>#pageCircle:before { display: block} </style>');
        $('head').append('<style>#pageCircle:after { display: block} </style>');
        pageView(bgClicked);
        $('#pageCircle').css({'backgroundColor': bgClicked});
        graph_color(color_mobile, color_pc, color_tablet, color_table_sum);
        carousel(1);
    }else if(location.pathname.match(/average_time_page/)){
        const color_mobile = "#64BE59";
        const color_pc = "#9BD195";
        const color_tablet = "#CFEBCC";
        const color_table_sum = "#3B9F2F";
        pseudo_class();
        $('#stayTimeCircle').css({'border': '1px solid #43A6DA', 'boxShadow': '0px 3px 6px #00000029'});
        $('head').append('<style>#stayTimeCircle:before { display: block} </style>');
        $('head').append('<style>#stayTimeCircle:after { display: block} </style>');
        avgStay(bgClicked);
        $('#stayTimeCircle').css({'backgroundColor': bgClicked});
        graph_color(color_mobile, color_pc, color_tablet, color_table_sum);
        carousel(-1);
    }else{
        const color_mobile = "#43A6DA";
        const color_pc = "#91D1F2";
        const color_tablet = "#BEE6FC";
        const color_table_sum = "#0A74AD";
        pseudo_class();
        $('#userCircle').css({'border': '1px solid #43A6DA', 'boxShadow': '0px 3px 6px #00000029'});
        $('head').append('<style>#userCircle:before { display: block} </style>');
        $('head').append('<style>#userCircle:after { display: block} </style>');
        user(bgClicked);
        $('#userCircle').css({'backgroundColor': bgClicked});
        graph_color(color_mobile, color_pc, color_tablet, color_table_sum);
        carousel(2);
    }

    if(gon.transitionScope === 'date'){
        $('.area-date').css({'backgroundColor':'#E5E5ED'});
    }else if(gon.transitionScope === 'isoYearIsoWeek'){
        $('.area-month').css({'backgroundColor':'#E5E5ED'});
        $('.area-date').css({'backgroundColor':'#fff'});
    }else if(gon.transitionScope === 'yearMonth'){
        $('.area-year').css({'backgroundColor':'#E5E5ED'});
        $('.area-date').css({'backgroundColor':'#fff'});
    }

    if(location.search.match(/comparison_scope=all/)){
        $('.all-area').css({'backgroundColor':'#E5E5ED'});
    }else if(location.search.match(/comparison_scope=jobType/)){
        $('.all-area').css({'backgroundColor':'#fff'});
        $('.job-type-area').css({'backgroundColor':'#E5E5ED'});
    }else if(location.search.match(/comparison_scope=field/)){
        $('.all-area').css({'backgroundColor':'#fff'});
        $('.field-area').css({'backgroundColor':'#E5E5ED'});
    }

    //カルーセル
    function carousel(index){
        let sliderWidth = 270;
        $('.all-circle-graph').css({'marginLeft': sliderWidth * index});
        $(window).on("orientationchange", function(){
            width = window.innerWidth;
            $('#useCircle').removeClass('current');
            $('#pageCircle').removeClass('current');
            $('#applicationCircke').removeClass('current');
            $('#stayTimeCircle').removeClass('current');
            $('#pageAverageCircle').removeClass('current');
            index = count;
            $('.all-circle-graph').children('div').eq(count).addClass('current');
            if(window.orientation === 0){
                if(window.innerHeight <= 414){
                    $('.circle').eq(Math.abs(count-2)).prev().css({'opacity': 0})
                    $('.circle').eq(Math.abs(count-2)).next().css({'opacity': 0})
                    $('.all-circle-graph').css({'marginLeft': sliderWidth * index});
                    $('.next').show();
                    $('.prev').show();
                }else if(window.innerHeight <= 720 || window.innerHeight == 768){
                    $('.circle').eq(Math.abs(count-2)).prev().css({'opacity': 0.32})
                    $('.circle').eq(Math.abs(count-2)).next().css({'opacity': 0.32})
                    $('.all-circle-graph').css({'marginLeft': sliderWidth * index});
                    $('.next').show();
                    $('.prev').show();
                }else{
                    $('.next').hide();
                    $('.prev').hide();
                    $('.all-circle-graph').css({'marginLeft': 0});
                }
            }else if(window.orientation === 90){
              if(window.innerHeight > 768){
                $('.all-circle-graph').css({'marginLeft': 0});
                $('.circle').css({'opacity': 1});
                $('.next').hide();
                $('.prev').hide();
              }else if(window.innerHeight > 414){
                $('.circle').eq(Math.abs(count-2)).prev().css({'opacity': 0.32})
                $('.circle').eq(Math.abs(count-2)).next().css({'opacity': 0.32})
              }
            }
          });
          if(window.innerWidth >768){
            $('.all-circle-graph').css({'marginLeft': 0});
          }
        let count = index;
        btn_display(count);
        function prev_loop(){
            count ++;
            $('.all-circle-graph').animate({'marginLeft': sliderWidth * count},'liner');
            btn_display(count);
        }

        function next_loop(){
            count--;
            $('.all-circle-graph').animate({'marginLeft': sliderWidth * count},'liner');
            btn_display(count);
        }

        function btn_display(count){
            if(count == 2){
                $('.prev').css({'visibility': 'hidden'});
            }else{
                $('.prev').css({'visibility': 'visible'});
            }
            if(count == -2){
                $('.next').css({'visibility': 'hidden'})
            }else{
                $('.next').css({'visibility': 'visible'});
            }
            if(window.innerWidth > 768){
                $('.next').hide();
                $('.prev').hide();
            }
            perspective_change(count)
        }

        function perspective_change(count){
            if(window.innerWidth <= 768){
                if(count == 2){
                    $('.circle').eq(0).css({'opacity': 1, 'transition': '0.5s'}); 
                }
                abs_count = Math.abs(count-1);
                let timer = '0.5s'
                if(window.innerWidth <= 414){
                    timer = '0.1s';
                    side_circle(0, timer);
                }else if(window.innerWidth <= 768){
                    side_circle(0.32, timer);
                }
                function side_circle(opacity, timer){
                    slider_center = $('.circle').eq(abs_count);
                    slider_center.css({'opacity': opacity, 'transition': timer});
                    slider_center.next().css({'opacity': 1, 'transition': timer});
                    slider_center.next().next().css({'opacity': opacity, 'transition': timer});
                }
            }
        }
        
        $('.prev').click(function(){
            prev_loop();
        });

        $('.next').click(function(){
            next_loop();
        });

        function setSwipe(elem) {
            if(width < 768){
                var t = document.querySelector(elem);
            var s_X;
            var e_X;
            var dist = 50;
            t.addEventListener('touchstart', function(e) {
                s_X = e.touches[0].pageX;
            });
            t.addEventListener('touchmove', function(e) {
                e_X = e.changedTouches[0].pageX;
            });
            t.addEventListener('touchend', function(e) {
                if (s_X > e_X + dist && count > -2) {//左にスワイプ
                    next_loop();
                } else if (s_X + dist < e_X && count < 2) {//右にスワイプ
                    prev_loop();
                } 
            });
            }
        }
        
        if(width <= 768){
            setSwipe('.all-circle-graph');
        }
    }
});
