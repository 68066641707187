// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("tablesorter")
require("select2")
require("js-cookie")
require ("js-cookie")
require("jquery-ui-dist/jquery-ui")
//require("select2-bootstrap4-theme")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import $ from 'jquery';
import jquery from 'jquery';
window.$ = window.jquery = jquery;
import Cookies from 'js-cookie';
// import Highcharts from 'highcharts'
// window.Highcharts = Highcharts;
import 'bootstrap'
import '../src/application.scss'
import '@fortawesome/fontawesome-free/js/all'
import 'select2'
import 'select2/dist/css/select2.css'
import 'select2-bootstrap4-theme/dist/select2-bootstrap4.css'
import '../src/js/admin/users'
import '../src/css/home/top'
import '../src/js/job_manuscript_analysis/summaries'
import '../src/js/job_manuscript_analysis/user_information'
import '../src/js/job_manuscript_analysis/applies'
import '../src/js/job_manuscript_analysis/inflow_sources'
import '../src/css/job_manuscript_analysis/summaries'
import '../src/css/job_manuscript_analysis/user_information'
import '../src/css/job_manuscript_analysis/applies'
import '../src/css/job_manuscript_analysis/inflow_sources'
import '../src/js/all_site_analysis/summaries'
import '../src/js/all_site_analysis/keywords'
import '../src/js/all_site_analysis/plan'
import '../src/css/all_site_alanysis/summaries'
import '../src/css/all_site_alanysis/keywords'
import '../src/css/all_site_alanysis/plan'

document.addEventListener("turbolinks:load", function() {
    Highcharts.setOptions({
        lang: {
            decimalPoint: '.',
            thousandsSep: ','
        }
    });

    let width = window.innerWidth;
    if(location.pathname.match(/summaries/)){
        $('body').css({'backgroundColor': '#ECF1F7'});
        $('.notifications').css({'marginTop': 0});
        if(width <= 414){
            $('.reload-btn').css({'top': 80});
        }else if(width <= 768){
            $('.reload-btn').css({'top': 30});
        }else{
            $('.reload-btn').css({'top': 50});
        }
    }else{
        $('body').css({'backgroundColor': '#FAFBFD'});
        $('.notifications').css({'marginTop': '40px'});
    }
    $('.js-searchable').select2()

    if(width <= 1024){
        if(width < window.innerHeight){
            $('.login-left').css({'maxWidth': 400})
        }
    }
    
    //Cookie
    $('#access_analysis').next().children('li').click(function () {
        Cookies.set("open-panel", "1");
    });
    $('.top-recruitment-list').on('click', '.recruit-content', function(){
        Cookies.set("open-panel", "1");
    });
    $('#setting').next().children('li').click(function () {
        Cookies.set("open-panel", "2");
    });
    $('.setting-link').click(function () {
        Cookies.set("open-panel", "2");
    });
    $('#all-site-analysis').next().children('li').click(function () {
        Cookies.set("open-panel", "3");
    });
    if (Cookies.get("open-panel") === "1") {
        cookie_down($('#access_analysis'), 'アクセス解析', '#161e22');
        if ($('.analysis-link').children('li').length === 3) {
            $('.side-design').children('li').eq(1).css({'height': 140});
        } else {
            $('.side-design').children('li').eq(1).css({'height': 175});
        }
    }else if (Cookies.get("open-panel") === "2") {
        cookie_down($('#setting'), '設定', '#161e22');
    }else if (Cookies.get("open-panel") === "3") {
        cookie_down($('#all-site-analysis'), 'サイト全体', '#161e22');
        $('.side-design').children('li').eq(2).css({'height': 175});
    }
    function cookie_down(element, text, color){
        element.next().show();
        element.empty();
        element.text(text);
        element.append('<i class="fas fa-chevron-down"></i>');
        element.append('<i class="fas fa-caret-left"></i>');
        element.css({'backgroundColor': '#161e22'});
        element.children('.fa-caret-left').css({'visibility': 'visible'})
        element.next().css({'backgroundColor': '#161e22'});
        mouseEvent(element, color)
    }

    $('.menu-title').each(function(){
        var self = $(this);
        $(this).children('.menu-main').click(function(){
            let index = self.index();
            if($(this).children('svg').hasClass('fa-chevron-right')){
                $(this).next().slideDown();
                $(this).children('svg').eq(0).removeClass('fa-chevron-right');
                $(this).children('svg').eq(0).addClass('fa-chevron-down');
                if ($(this)[0].classList[1] === 'access' && $('.analysis-link').children('li').length === 3) {
                    $('.side-design').children('li').eq(index).animate(
                        {height: "140px"},'swing');
                } else {
                    $('.side-design').children('li').eq(index).animate(
                        {height: "175px"},'swing');
                }
                $(this).css({'backgroundColor': '#2a4f76'});
                $(this).next().css({'backgroundColor':'#161e22'});
                $(this).children('svg').eq(1).css({'visibility':'visible', 'color':'#2a4f76'});
                const color = '#161e22'
                mouseEvent($(this), color);
            }else{
                $(this).next().slideUp();
                $(this).children('svg').eq(0).addClass('fa-chevron-right');
                $(this).children('svg').eq(0).removeClass('fa-chevron-down');
                $('.side-design').children('li').eq(index).animate(
                    {height: "35px"},'swing');
                $(this).css({'backgroundColor':'#1e3755'});
                $(this).children('svg').eq(1).css({'visibility':'hidden'})
                const color = '#1e3755';
                mouseEvent($(this), color);
            }
        });
    });
    function mouseEvent(element, color){
        element.mouseover(function(){
            element.css({'backgroundColor': '#2a4f76'});
            element.children('svg').eq(1).css({'color':'#2a4f76'});
        }).mouseout(function(){
            element.css({'backgroundColor': color});
            element.children('svg').eq(1).css({'color':'#161e22'});
        });
    }
    $(this).mouseover(function(){
        $(this).css({'backgroundColor': '#2a4f76'});
        $(this).children('svg').eq(1).css({'color':'#2a4f76'});
    }).mouseout(function(){
        $(this).css({'backgroundColor': '#1e3755'});
        $(this).children('svg').eq(1).css({'color':'#161e22'});
    });

    let bodyRight = $('body').css('marginRight');
    // ユーザーのモーダルの表示
    const modal = $('.user-modal');
    const overlay = $('.overlay');
    modal.css({'right':bodyRight});
    overlay.hide();
    $('#userModal, #user_modal_icon').click(function(){
        modal.animate({opacity: 1},'fast').css({'zIndex':'3'});
        overlay.show().css({'zIndex':'3'});
    });
    overlay.click(function(){
        modal.animate({opacity: 0},'fast').css({'zIndex':'-2'});
        overlay.hide().css({'zIndex':'-2'});
    });

    $('.admin-user-table').each(function(){
        let userTable = $(this).children('div').children('span').eq(0).children('svg');
        $(this).click(function(){
            if($(this).children('div').children('span').eq(0).children('svg').hasClass('fa-caret-right')){
            $(this).next().slideDown();
            $(this).children('div').children('span').eq(0).children('svg').removeClass('fa-caret-right');
            $(this).children('div').children('span').eq(0).children('svg').addClass('fa-caret-down');
            }else{
            $(this).next().slideUp();
            $(this).children('div').children('span').eq(0).children('svg').removeClass('fa-caret-down');
            $(this).children('div').children('span').eq(0).children('svg').addClass('fa-caret-right');
            }
        });
    });

    // 求人原稿のモーダルの表示
    const recruitModal = $('.recruit-search-modal');
    const overlayRecruit = $('#overlay-recruit');
    $('.recruit-company-name').each(function(){
        $(this).click(function(){
        if($(this).children('svg').hasClass('fa-caret-right')){
            $(this).next().slideDown();
            $(this).children('svg').removeClass('fa-caret-right');
            $(this).children('svg').addClass('fa-caret-down');
        }else{
            $(this).next().slideUp();
            $(this).children('svg').removeClass('fa-caret-down');
            $(this).children('svg').addClass('fa-caret-right');
        }
        });
    });

    //ハンバーガーメニューの表示
    const humb = $('#hamburger');
    humb.addClass('borders');
    humb.click(function(){
        if($(this).hasClass('borders')){
            $('nav').css({'height':'100%', 'opacity':'1', 'zIndex':'5', 'transition':'0.4s'});
            humb.removeClass('borders');
            humb.children('p').eq(0).css({'transform': 'rotate(-45deg)', 'transformOrigin':'right top', 'transition': '0.2s'});
            humb.children('p').eq(1).css({'visibility': 'hidden'});
            humb.children('p').eq(2).css({'transform': 'rotate(45deg)', 'transformOrigin':'right bottom', 'transition': '0.2s'});
        }else{
            resetHamberger();
        }
    });

    function resetHamberger(){
        $('nav').css({'opacity':'0', 'zIndex':'-5', 'transition':'0.4s'});
        humb.addClass('borders');
        humb.children('p').eq(0).css({'transform': 'rotate(0)', 'transition': '0.2s'});
        humb.children('p').eq(1).css({'visibility': 'visible'});
        humb.children('p').eq(2).css({'transform': 'rotate(0)', 'transition': '0.2s'});
    }

    //フラッシュメッセージ
    $('.login-notifications, .notifications, .valid_errors').hide().fadeIn(200);
    $('.login-notifications, .notifications, .valid_errors').animate({
        opacity: 1
    }, 500);
    // setTimeout("$('.login-notifications, .notifications').animate({opacity:0})", 2000);

    // let width = window.innerWidth;

    // if(width >= 1024){
    //     $('#smallDevice').hide();
    //     $('#bigDevice').show()
    // }else{
    //     $('#smallDevice').show();
    //     $('#bigDevice').hide();
    // }

    let topDateContentsWidth = $('.top-date-contents').width();
    $('.top-company-contents').css({'width': topDateContentsWidth});
    let topDateInputWidth = $('.top-date-input').width();
    $('#q_posting_company_id_eq').css({'width': topDateInputWidth});
    $('#q_posting_company_agency_id_eq').css({'width': topDateInputWidth});

    // ロード画面
    $('#is_loading').delay(0).fadeOut(100);
    $('#loading').delay(0).fadeOut(100, addContents);
    function addContents(){
        $('#loadingWindow').css('display', 'block');
    }

    $('.recruit-content, .reload-graph-link, .modal-close').on('click',loading);
    $('.load-btn').on('click',function(){
        loading();
        window.location.reload(true);
    })

    if(width <= 414){
        $('.reload-summaries').on('click',function(){
            loading();
            resetHamberger();
        });
    }else{
        $('.reload-summaries').on('click',loading);
    }

    if(location.pathname.match(/home/)){
        $('header').css({'height': 37});
    }

    $('#job-manuscripts-import-modal, #organizations-import-modal, #posting-companies-import-modal, #posting-plans-import-modal, #users-import-modal').on('hidden.bs.modal', function () {
        location.reload();
    })
});

function loading(){
    let h = $(window).height();
    let headerHeight = $('header').height();
    $('.all-content').css({'marginTop': headerHeight});
    $('.home').css({'marginTop': headerHeight});
    $('#is_loading ,#loading').height(h).css('display','block');
    $('#loadingWindow').css('display', 'none');
}

require("trix")
require("@rails/actiontext")
