document.addEventListener("turbolinks:load", function() {
    // 年齢別割合
    Highcharts.chart({
        colors: ["#F54200", "#FD8039", "#43A6DA", "#91D1F2", "#707070", "#D3D3D3"],
        chart: {
            renderTo: 'subscriptionsAge',
            // どんなタイプのグラフを表示させるか
            type: 'pie'
        },
        title: {
            // グラフのタイトル
            text: ''
        },
        plotOptions: {
            pie: {
                // 円グラフの内側のサイズ
                innerSize: '70%',
                dataLabels: {
                    color: 'black',
                    formatter: function() {
                        return '<b>'+ this.point.name +'</b>'+'<br>'+ Math.round(this.percentage*10)/10 +'%';
                    },
                    enabled: true,
                    softConnector: false,
                    distance: 10
                }
            }
        },
        // 表示させるデータ
        series: [
            {
                name: 'ユーザー数',
                data: gon.agePerUser
            }
        ],
        exporting: { 
            enabled: false 
        },
        credits:
            {
                enabled: false,
            }
    });


});
