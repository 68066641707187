document.addEventListener("turbolinks:load", function() {
    // ランキング棒グラフ
    function chart(example, title, data){ Highcharts.chart({
        colors: ["#43A6DA", "#91D1F2", "#E66118"],
        chart: {
            height: '200',
            backgroundColor:'#fff',
            renderTo: example,
            // どんなタイプのグラフを表示させるか
            type: 'column'
        },
        tooltip: {
            enabled: false // カーソルを置いたときに吹き出しを表示しない
        },
        title: {
            // グラフのタイトル
            text: title,
            verticalAlign: 'bottom',
            style: { 'color': '#AAAAAA', 'font-size': '15px'}
        },
        xAxis: {
            lineWidth: 1,
            gridLineColor: 'transparent',
            labels: {
                enabled: false
            },
            tickColor: '#fff'
        },
        yAxis: {
            lineWidth: 0,
            lineColor: 'transparent',
            visible: false
        },
        plotOptions: {
            column: {colorByPoint: true}
        },
        // 表示させるデータ
        series: [
            {
                //name: '平均ページビュー',
                showInLegend: false,
                dataLabels:
                    {
                        enabled: false
                    },
                data: data,
                pointWidth: 28
            }
        ],
        exporting: { 
            enabled: false 
        },
        credits: {
            enabled: false,
        }
    });
    }
    chart(stickGraphSearch, '検索エンジン', gon.search);
    chart(stickGraphRecruit, 'アグリゲート型求人', gon.aggregate);
    chart(stickGraphRink, '外部リンク', gon.external_link);
    chart(stickGraphAddvettisement, 'リスティング広告', gon.listing);
    chart(stickGraphSocial, 'ソーシャル', gon.social);
    chart(stickGraphOther, 'その他', gon.other);

    const flow_tr = $('.flow-resource-information').children('div').children('table').children('tbody').children('tr');
    let flow_tr_length = flow_tr.length
    let num = 10;
    let delete_flow_tr = []
    while(num < flow_tr_length){
        delete_flow_tr[num] = flow_tr.eq(num).detach();
        num++;
    }

    if(flow_tr_length <= 10){
        $('#more_flow_resources_btn').hide();
    }

    let count = 2;
    num = 10;
    $('#more_flow_resources_btn').click(function(){
        while(num < count * 10){
            $("#details-table").append(delete_flow_tr[num]);
            if(num == flow_tr_length){
                $(this).hide();
                break;
            }
            num++;
        }
        $("#details-table").trigger('update');
        count++;
    });

    $("#details-table").tablesorter({
        headers: {
            0: { sorter: false },
            1: {
                lockedOrder: 'desc',
                sorter: 'fancyNumber'
            },
            2: {
                lockedOrder: 'desc',
                sorter: 'fancyNumber'
            },
            3: {
                lockedOrder: 'desc',
                sorter: 'fancyNumber'
            },
            4: {
                lockedOrder: 'desc',
                sorter: 'fancyNumber'
            },
        },
        sortList: [ [ 1, 1 ] ]
    });
    
});
