const { numberFormat } = require("highcharts");

document.addEventListener("turbolinks:load", function() {
    let width = window.innerWidth;

    const bgOrigin = '#FAFBFD'
    const bgClicked = '#fff'

    // ユーザー数
    function user(color){
        Highcharts.chart({
            colors: ["#43A6DA", "#91D1F2", "#BEE6FC"],
            chart: {
                // styledMode: true,
                backgroundColor: color,
                renderTo: 'allUserCircle',
                // どんなタイプのグラフを表示させるか
                type: 'pie'
            },
            title: {
                // グラフのタイトル
                text: 'ユーザー数'
            },
            tooltip: {
                enabled: false // カーソルを置いたときに吹き出しを表示しない
            },
            exporting: { enabled: false },
            plotOptions: {
                pie: {
                    // 円グラフの内側のサイズ
                    innerSize: '79%',
                    // style: {
                    //     marginTop: '-100px'
                    // }
                }
            },
            // 表示させるデータ
            series: [
                {
                    name: 'ユーザー数',
                    dataLabels:
                        {
                            enabled:false
                        },
                    data: [
                        // 進捗25%、残り75%
                        [ 'モバイル', gon.userMobile],
                        [ 'PC', gon.userPc ],
                        [ 'タブレット', gon.userTablet ]
                    ]
                }
            ],
            credits:
                {
                    enabled: false,
                }
        });
    }

    user(bgOrigin)

    //ページビュー
    function pageView(color){
        Highcharts.chart({
            colors: ["#1CAFB7", "#81DCE2", "#CFF9FC"],
            chart: {
                backgroundColor: color,
                renderTo: 'allPageCircle',
                // どんなタイプのグラフを表示させるか
                type: 'pie'
            },
            title: {
                // グラフのタイトル
                text: 'ページビュー'
            },
            tooltip: {
                enabled: false // カーソルを置いたときに吹き出しを表示しない
            },
            exporting: { enabled: false },
            plotOptions: {
                pie: {
                    // 円グラフの内側のサイズ
                    innerSize: '79%'
                }
            },
            // 表示させるデータ
            series: [
                {
                    name: 'ページビュー',
                    dataLabels:
                        {
                            enabled:false
                        },
                    data: [
                        // 進捗25%、残り75%
                        [ 'モバイル', gon.pageViewMobile ],
                        [ 'PC', gon.pageViewPc ],
                        [ 'タブレット', gon.pageViewTablet ]
                    ]
                }
            ],
            credits:
                {
                    enabled: false,
                }
        });
    }

    pageView(bgOrigin)

    //応募数
    function app(color){
        Highcharts.chart({
            colors: ["#E66118", "#FFA26E", "#FFD7C1"],
            chart: {
                backgroundColor: color,
                renderTo: 'allApplicationCircle',
                // どんなタイプのグラフを表示させるか
                type: 'pie'
            },
            title: {
                // グラフのタイトル
                text: '応募数'
            },
            tooltip: {
                enabled: false // カーソルを置いたときに吹き出しを表示しない
            },
            exporting: { enabled: false },
            plotOptions: {
                pie: {
                    // 円グラフの内側のサイズ
                    innerSize: '79%'
                }
            },
            // 表示させるデータ
            series: [
                {
                    name: '応募数',
                    dataLabels:
                        {
                            enabled:false
                        },
                    data: [
                        // 進捗25%、残り75%
                        [ 'モバイル', gon.applicationMobile ],
                        [ 'PC', gon.applicationPc ],
                        [ 'タブレット', gon.applicationTablet ]
                    ]
                }
            ],
            credits:
                {
                    enabled: false,
                }
        });
    }

    app(bgOrigin)

    //平均滞在時間
    function avgStay(color){
        Highcharts.chart({
            colors: ["#64BE59", "#9BD195", "#CFEBCC"],
            chart: {
                backgroundColor: color,
                renderTo: 'allStayTimeCircle',
                // どんなタイプのグラフを表示させるか
                type: 'pie'
            },
            title: {
                // グラフのタイトル
                text: '平均滞在時間'
            },
            tooltip: {
                enabled: false // カーソルを置いたときに吹き出しを表示しない
            },
            exporting: { enabled: false },
            plotOptions: {
                pie: {
                    // 円グラフの内側のサイズ
                    innerSize: '79%'
                }
            },
            // 表示させるデータ
            series: [
                {
                    name: '平均滞在時間',
                    dataLabels:
                        {
                            enabled:false
                        },
                    data: [
                        // 進捗25%、残り75%
                        [ 'モバイル', gon.averageTimePc ],
                        [ 'PC', gon.averageTimeMobile ],
                        [ 'タブレット', gon.averageTimeTablet ]
                    ]
                }
            ],
            credits:
                {
                    enabled: false,
                }
        });
    }

    avgStay(bgOrigin)

    //平均ページビュー
    function avgpage(color) {
        Highcharts.chart({
            colors: ["#F25B8D", "#FF98BA", "#FFD8E5"],
            chart: {
                backgroundColor: color,
                renderTo: 'allPageAverageCircle',
                // どんなタイプのグラフを表示させるか
                type: 'pie'
            },
            title: {
                // グラフのタイトル
                text: '平均ページビュー'
            },
            tooltip: {
                enabled: false // カーソルを置いたときに吹き出しを表示しない
            },
            exporting: { enabled: false },
            plotOptions: {
                pie: {
                    // 円グラフの内側のサイズ
                    innerSize: '79%',
                    // tooltip: {
                    //     shared: true
                    // }
                }
            },
            // 表示させるデータ
            series: [
                {
                    name: '平均ページビュー',
                    dataLabels:
                        {
                            enabled:false
                        },
                    data: [
                        // 進捗25%、残り75%
                        [ 'モバイル', gon.averagePageViewPc ],
                        [ 'PC', gon.averagePageViewMobile ],
                        [ 'タブレット', gon.averagePageViewTablet ]
                    ]
                }
            ],
            credits:
                {
                    enabled: false,
                }
        });
    }

    avgpage(bgOrigin)

    //折れ線グラフ
    let date_interval = 1;
    if (width <= 414){
        if(gon.line_device_date.length >= 360){
            date_interval = 73;
        }else if(gon.line_device_date.length >= 330){
            date_interval = 67;
        }else if(gon.line_device_date.length >= 300){
            date_interval = 61;
        }else if(gon.line_device_date.length >= 270){
            date_interval = 55;
        }else if(gon.line_device_date.length >= 240){
            date_interval = 49;
        }else if(gon.line_device_date.length >= 210){
            date_interval = 43;
        }else if(gon.line_device_date.length >= 180){
            date_interval = 37;
        }else if(gon.line_device_date.length >= 150){
            date_interval = 31;
        }else if(gon.line_device_date.length >= 120){
            date_interval = 25;
        }else if(gon.line_device_date.length >= 90){
            date_interval = 19;
        }else if(gon.line_device_date.length >= 60){
            date_interval = 13;
        }else if(gon.line_device_date.length >= 30){
            date_interval = 7;
        }else if(gon.line_device_date.length >= 22){
            date_interval = 4;
        }else if(gon.line_device_date.length >= 14){
            date_interval = 3;
        }else if(gon.line_device_date.length >= 8){
            date_interval = 2;
        }
    }else if(width <= 768){
        dataInterval();
    }else if(width <= 1024){
        dataInterval();
    }else{
        dataInterval();
    }

    function dataInterval(){
        if(gon.line_device_date.length >= 360){
            date_interval = 52;
        }else if(gon.line_device_date.length >= 330){
            date_interval = 48;
        }else if(gon.line_device_date.length >= 300){
            date_interval = 43;
        }else if(gon.line_device_date.length >= 270){
            date_interval = 39;
        }else if(gon.line_device_date.length >= 240){
            date_interval = 35;
        }else if(gon.line_device_date.length >= 210){
            date_interval = 30;
        }else if(gon.line_device_date.length >= 180){
            date_interval = 26;
        }else if(gon.line_device_date.length >= 150){
            date_interval = 22;
        }else if(gon.line_device_date.length >= 120){
            date_interval = 18;
        }else if(gon.line_device_date.length >= 90){
            date_interval = 13;
        }else if(gon.line_device_date.length >= 60){
            date_interval = 9;
        }else if(gon.line_device_date.length >= 30){
            date_interval = 6;
        }else if(gon.line_device_date.length >= 22){
            date_interval = 4;
        }else if(gon.line_device_date.length >= 14){
            date_interval = 3;
        }else if(gon.line_device_date.length >= 8){
            date_interval = 2;
        }
    }

    function all_device_line_chart(color_mobile, color_pc, color_tablet){
        Highcharts.chart({
            chart: {
                renderTo: 'allTransition', //表示するid名
                defaultSeriesType: 'area', //グラフのタイプline, spline, area, areaspline, column, bar, pie,scatter
            },
            title: {
                text: '' //グラフタイトル
            },
            xAxis: { //X軸
                labels: {
                    rotation: 0
                },
                categories: gon.line_device_date,
                tickInterval: date_interval
            },
            yAxis: { //Y軸
                title: {text: ''} //タイトル
            },
            plotOptions: {
                area: {
                    stacking: 'normal'
                }
            },
            exporting: {
                enabled: false
            },
            legend: { //項目名
                align: 'left',
                verticalAlign: 'top',
                symbolRadius: 0,
                itemDistance: 10,
                itemStyle: {
                    fontWeight: 'normal'
                }
                
            },
            series: [ //ここからグラフ内容指定
                {
                    name: 'モバイル',
                    data: gon.line_mobile,
                    color: color_mobile
                },{
                    name: 'PC',
                    data: gon.line_pc,
                    color: color_pc
                },{
                    name: 'タブレット',
                    data: gon.line_tablet,
                    color: color_tablet
                }],
            credits: {
                enabled: false,
            }
        });
    }

    //新規orリピーター円グラフ
    function all_judge_circle(color_new, color_repeat){
        Highcharts.chart({
            colors: [color_new, color_repeat],
            chart: {
                backgroundColor:'#FFF',
                renderTo: 'allJudgeCircle',
                // どんなタイプのグラフを表示させるか
                type: 'pie'
            },
            title: {
                // グラフのタイトル
                text: ''
            },
            exporting: { enabled: false },
            plotOptions: {
                pie: {
                    // 円グラフの内側のサイズ
                    innerSize: '79%',
                    dataLabels: {
                        formatter: function() {
                            return '<b>' + this.point.name + '</b>' +'<br>'+ Math.round(this.percentage * 10) / 10 +'%';
                        },
                        enabled: true,
                        softConnector: false,
                        distance: 5,
                    },

                }
            },
            // 表示させるデータ
            series: [
                {
                    name: gon.data_name + '数',
                    data: [
                        // 進捗25%、残り75%
                        [ gon.newVisitorTitle, gon.newVisitor ],
                        [ gon.returningVisitorTitle, gon.returningVisitor ]
                    ]
                }
            ],
            credits:
                {
                    enabled: false,
                }
        });
    }

    //曜日毎の棒グラフ
    function all_day_stick_chart(color){
        new Highcharts.Chart({
            chart: {
                renderTo: 'all_day_of_week_stick', //表示するid名
                defaultSeriesType: 'column', //グラフのタイプline, spline, area, areaspline, column, bar, pie,scatter
            },
            title: {
                text: '曜日毎の平均' + gon.column_title, //グラフタイトル
                margin: 40,
                align: 'left'
            },
            xAxis: { //X軸
                categories: gon.column_week_day, //ラベル
                labels: {
                    rotation: 0
                }
            },
            yAxis: { //Y軸
                title: {text: ''} //タイトル
            },
            plotOptions: {
                area: {
                    stacking: 'normal'
                }
            },
            exporting: {
                enabled: false
            },
            legend: { //項目名
                enabled: false
            },
            series: [ //ここからグラフ内容指定
                {
                    name: gon.data_name,
                    data: gon.column_week_data,
                    color: color
                }],
            credits: {
                enabled: false
            }
        });
    }

    //時間毎の折れ線グラフ
    function all_time_line_chart(color){
        new Highcharts.Chart({
            chart: {
                renderTo: 'all_day_of_hour_stick', //表示するid名
                defaultSeriesType: 'line', //グラフのタイプline, spline, area, areaspline, column, bar, pie,scatter
            },
            title: {
                text: '時間毎' + gon.line_title, //グラフタイトル
                margin: 40,
                align: 'left'
            },
            xAxis: { //X軸
                categories: gon.line_time_hour //ラベル
            },
            yAxis: { //Y軸
                title: {text: ''} //タイトル
            },
            plotOptions: {
                area: {
                    stacking: 'normal'
                }
            },
            exporting: {
                enabled: false
            },
            legend: { //項目名
                enabled: false
            },
            series: [ //ここからグラフ内容指定
                {
                    name: gon.data_name,
                    data: gon.line_time_data,
                    color: color
                }],
            credits: {
                enabled: false,
            }
        });
    }

    const circle_mobile = $('.device-type').children('li').eq(2).children('span');
    const circle_pc = $('.device-type').children('li').eq(1).children('span');
    const circle_tablet = $('.device-type').children('li').eq(0).children('span');
    const mobile = $('.line-device-type').children('li').eq(2).children('span');
    const pc = $('.line-device-type').children('li').eq(1).children('span');
    const tablet = $('.line-device-type').children('li').eq(0).children('span');
    const device_rate = $('.device').children('span');
    const lank_sentence = $('.lank-sentence').children('p');
    const sales_table_head = $('.salse_table-information-title').children('th');
    const sales_table_head_sum = sales_table_head.eq(4);

    function all_graph_color(color_mobile, color_pc, color_tablet, color_table_sum){
        all_device_line_chart(color_mobile, color_pc, color_tablet);
        all_judge_circle(color_mobile, color_pc);
        all_day_stick_chart(color_mobile);
        all_time_line_chart(color_mobile);
        circle_mobile.css({'color': color_tablet});
        circle_pc.css({'color': color_pc});
        circle_tablet.css({'color': color_mobile});
        mobile.css({'color': color_mobile});
        pc.css({'color': color_pc});
        tablet.css({'color': color_tablet});
        device_rate.css({'color': color_mobile});
        lank_sentence.css({'color': color_mobile});
        sales_table_head.css({'backgroundColor': color_mobile});
        sales_table_head_sum.css({'backgroundColor': color_table_sum});
        $('#data_title').children('div').css({'borderColor': color_mobile});
        $('.area-date').css({'backgroundColor':'#E5E5ED'});
    }

    function all_pseudo_class(){
        $('head').append('<style>#allPageAverageCircle:before { display: none} </style>');
        $('head').append('<style>#allPageAverageCircle:after { display: none} </style>');
        $('head').append('<style>#allApplicationCircle:before { display: none} </style>');
        $('head').append('<style>#allApplicationCircle:after { display: none} </style>');
        $('head').append('<style>#allPageCircle:before { display: none} </style>');
        $('head').append('<style>#allPageCircle:after { display: none} </style>');
        $('head').append('<style>#allStayTimeCircle:before { display: none} </style>');
        $('head').append('<style>#allStayTimeCircle:after { display: none} </style>');
        $('head').append('<style>#allUserCircle:before { display: none} </style>');
        $('head').append('<style>#allUserCircle:after { display: none} </style>');
    }

    $('#allPageCircle').css({'border': 'none'});
    $('#allApplicationCircle').css({'border': 'none'});

    // グラフの色を変える
    if(location.pathname == "/all_site_analysis/summaries/average_page_view"){
        const color_mobile = "#F25B8D";
        const color_pc = "#FF98BA";
        const color_tablet = "#FFD8E5";
        const color_table_sum = "#D64676";
        all_pseudo_class();
        $('#allPageAverageCircle').css({'border': '1px solid #43A6DA', 'boxShadow': '0px 3px 6px #00000029'});
        $('head').append('<style>#allPageAverageCircle:before { display: block} </style>');
        $('head').append('<style>#allPageAverageCircle:after { display: block} </style>');
        avgpage(bgClicked);
        $('#allPageAverageCircle').css({'backgroundColor': bgClicked});
        all_graph_color(color_mobile, color_pc, color_tablet, color_table_sum);
        all_carousel(-2);
    }else if(location.pathname == "/all_site_analysis/summaries/apply"){
        const color_mobile = "#E66118";
        const color_pc = "#FFA26E";
        const color_tablet = "#FFD7C1";
        const color_table_sum = "#E66118";
        all_pseudo_class();
        $('#allApplicationCircle').css({'border': '1px solid #43A6DA', 'boxShadow': '0px 3px 6px #00000029'});
        $('head').append('<style>#allApplicationCircle:before { display: block} </style>');
        $('head').append('<style>#allApplicationCircle:after { display: block} </style>');
        app(bgClicked);
        $('#allApplicationCircle').css({'backgroundColor': bgClicked});
        all_graph_color(color_mobile, color_pc, color_tablet, color_table_sum);
        all_carousel(0);
    }else if(location.pathname == "/all_site_analysis/summaries/page_view"){
        const color_mobile = "#1CAFB7";
        const color_pc = "#81DCE2";
        const color_tablet = "#CFF9FC";
        const color_table_sum = "#3796A4";
        all_pseudo_class();
        $('#allPageCircle').css({'border': '1px solid #43A6DA', 'boxShadow': '0px 3px 6px #00000029'});
        $('head').append('<style>#allPageCircle:before { display: block} </style>');
        $('head').append('<style>#allPageCircle:after { display: block} </style>');
        pageView(bgClicked);
        $('#allPageCircle').css({'backgroundColor': bgClicked});
        all_graph_color(color_mobile, color_pc, color_tablet, color_table_sum);
        all_carousel(1);
    }else if(location.pathname == "/all_site_analysis/summaries/average_time_page"){
        const color_mobile = "#64BE59";
        const color_pc = "#9BD195";
        const color_tablet = "#CFEBCC";
        const color_table_sum = "#3B9F2F";
        all_pseudo_class();
        $('#allStayTimeCircle').css({'border': '1px solid #43A6DA', 'boxShadow': '0px 3px 6px #00000029'});
        $('head').append('<style>#allStayTimeCircle:before { display: block} </style>');
        $('head').append('<style>#allStayTimeCircle:after { display: block} </style>');
        avgStay(bgClicked);
        $('#allStayTimeCircle').css({'backgroundColor': bgClicked});
        all_graph_color(color_mobile, color_pc, color_tablet, color_table_sum);
        all_carousel(-1);
    }else{
        const color_mobile = "#43A6DA";
        const color_pc = "#91D1F2";
        const color_tablet = "#BEE6FC";
        const color_table_sum = "#0A74AD";
        all_pseudo_class();
        $('#allUserCircle').css({'border': '1px solid #43A6DA', 'boxShadow': '0px 3px 6px #00000029'});
        $('head').append('<style>#allUserCircle:before { display: block} </style>');
        $('head').append('<style>#allUserCircle:after { display: block} </style>');
        user(bgClicked);
        $('#allUserCircle').css({'backgroundColor': bgClicked});
        all_graph_color(color_mobile, color_pc, color_tablet, color_table_sum);
        all_carousel(2);
    }

    if(gon.transitionScope === 'date'){
        $('.area-date').css({'backgroundColor':'#E5E5ED'});
    }else if(gon.transitionScope === 'isoYearIsoWeek'){
        $('.area-month').css({'backgroundColor':'#E5E5ED'});
        $('.area-date').css({'backgroundColor':'#fff'});
    }else if(gon.transitionScope === 'yearMonth'){
        $('.area-year').css({'backgroundColor':'#E5E5ED'});
        $('.area-date').css({'backgroundColor':'#fff'});
    }

    if(location.search.match(/transition_scope=date/)){
        $('.area-date').css({'backgroundColor':'#E5E5ED'});
    }else if(location.search.match(/transition_scope=isoYearIsoWeek/)){
        $('.area-month').css({'backgroundColor':'#E5E5ED'});
        $('.area-date').css({'backgroundColor':'#fff'});
    }else if(location.search.match(/transition_scope=yearMonth/)){
        $('.area-year').css({'backgroundColor':'#E5E5ED'});
        $('.area-date').css({'backgroundColor':'#fff'});
    }

    //カルーセル
    function all_carousel(index){
        let sliderWidth = 270;
        $('.all-all-circle-graph').css({'marginLeft': sliderWidth * index});
        $(window).on("orientationchange", function(){
            width = window.innerWidth;
            $('#allUserCircle').removeClass('current');
            $('#allPageCircle').removeClass('current');
            $('#allApplicationCircle').removeClass('current');
            $('#allStayTimeCircle').removeClass('current');
            $('#allPageAverageCircle').removeClass('current');
            index = count;
            $('.all-all-circle-graph').children('div').eq(count).addClass('current');
            if(window.orientation === 0){
                if(window.innerHeight <= 414){
                    $('.all-circle').eq(Math.abs(count-2)).prev().css({'opacity': 0})
                    $('.all-circle').eq(Math.abs(count-2)).next().css({'opacity': 0})
                    $('.all-all-circle-graph').css({'marginLeft': sliderWidth * index});
                    $('.all-next').show();
                    $('.all-prev').show();
                }else if(window.innerHeight <= 720 || window.innerHeight == 768){
                    $('.all-circle').eq(Math.abs(count-2)).prev().css({'opacity': 0.32})
                    $('.all-circle').eq(Math.abs(count-2)).next().css({'opacity': 0.32})
                    $('.all-all-circle-graph').css({'marginLeft': sliderWidth * index});
                    $('.all-next').show();
                    $('.all-prev').show();
                }else{
                    $('.all-next').hide();
                    $('.all-prev').hide();
                    $('.all-all-circle-graph').css({'marginLeft': 0});
                }
            }else if(window.orientation === 90){
              if(window.innerHeight > 768){
                $('.all-all-circle-graph').css({'marginLeft': 0});
                $('.all-circle').css({'opacity': 1})
                $('.all-next').hide();
                $('.all-prev').hide();
              }else if(window.innerHeight > 414){
                $('.all-circle').eq(Math.abs(count-2)).prev().css({'opacity': 0.32})
                $('.all-circle').eq(Math.abs(count-2)).next().css({'opacity': 0.32})
              }
            }
        });
        if(window.innerWidth >768){
            $('.all-all-circle-graph').css({'marginLeft': 0});
        }
        count = index;
        all_btn_display(count);
        function all_prev_loop(){
            count ++;
            $('.all-all-circle-graph').animate({'marginLeft': sliderWidth * count},'liner');
            all_btn_display(count);
        }

        function all_next_loop(){
            count--;
            $('.all-all-circle-graph').animate({'marginLeft': sliderWidth * count},'liner');
            all_btn_display(count);
        }

        function all_btn_display(count){
            if(count == 2){
                $('.all-prev').css({'visibility': 'hidden'});
            }else{
                $('.all-prev').css({'visibility': 'visible'});
            }
            if(count == -2){
                $('.all-next').css({'visibility': 'hidden'})
            }else{
                $('.all-next').css({'visibility': 'visible'});
            }
            if(window.innerWidth > 768){
                $('.all-next').hide();
                $('.all-prev').hide();
            }
            perspective_change(count)
        }

        function perspective_change(count){
            if(window.innerWidth <= 768){
                if(count == 2){
                    $('.all-circle').eq(0).css({'opacity': 1, 'transition': '0.5s'});
                }
                abs_count = Math.abs(count-1);
                let timer = '0.5s'
                if(window.innerWidth <= 414){
                    timer = '0.1s';
                    side_circle(0, timer);
                }else if(window.innerWidth <= 768){
                    side_circle(0.32, timer);
                }
                function side_circle(opacity, timer){
                    slider_center = $('.all-circle').eq(abs_count);
                    slider_center.css({'opacity': opacity, 'transition': timer});
                    slider_center.next().css({'opacity': 1, 'transition': timer});
                    slider_center.next().next().css({'opacity': opacity, 'transition': timer});
                }
            }
        }

        $('.all-prev').click(function(){
            all_prev_loop();
        });

        $('.all-next').click(function(){
            all_next_loop();
        });

        function allSetSwipe(elem) {
            var t = document.querySelector(elem);
            var s_X;
            var e_X;
            var dist = 50;
            t.addEventListener('touchstart', function(e) {
                s_X = e.touches[0].pageX;
            });
            t.addEventListener('touchmove', function(e) {
                e_X = e.changedTouches[0].pageX;
            });
            t.addEventListener('touchend', function(e) {
                if (s_X > e_X + dist && count > -2) {//左にスワイプ
                    all_next_loop();
                } else if (s_X + dist < e_X && count < 2) {//右にスワイプ
                    all_prev_loop();
                }
            });
        }
        if(window.innerWidth <= 768){
            allSetSwipe('.all-all-circle-graph');
        }
    }

});
